<template>
<div class="indexlayout-right-footer">
  <div class="footer-links" >
    <a href="#" target="_blank"> Copyright © 2021 松城社区卫生服务中心 All Rights Reserved </a>
         <el-popover placement="top-start" width="300" trigger="hover" >
      <img src="https://xpjk.3dy.me/xpcdc.com/static/c.robber.jpg" style="width:300px;height:300px">
     
      <el-button slot="reference" type="text" style="font-weight:600;margin-left:-10px;font-size:14px"> <svg-icon icon-class="weichat"  style="font-size:20px;margin-right:5px"/>技术支持</el-button>
    </el-popover>
  </div>
  <div class="footer-copyright"  @click="dialogVisible=true">
    软件开发：福建省集智天辰信息技术有限公司 

  </div>

</div>
</template>

<script>
export default {
  name: "LayoutIndexRightFooter",
  data() {
    return {
      dialogVisible: false
    };
  }
};
</script>

<style lang="scss" scoped>
.indexlayout-right-footer {
  padding: 48px 0 24px;
  height: 50px;
  overflow: hidden;
  text-align: center;
  font-size: 14px;
  color: #808695;

  .footer-links {
    margin-bottom: 10px;

    a {
      margin-right: 30px;
      color: #808695;
      text-decoration: none;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: #515a6e;
      }
    }
  }
}
</style>
