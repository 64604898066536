/**
 * 页面示例-路由配置
 * @author C.Robber
 */

//import SingleLayoutIndex from '@/layout/Index';

const roleRouter = {
  path: '/role',
  name: 'role',
  component: () => import('@/views/UserRole/user'),
  meta: {
    title: '医生管理',
    icon: 'page',
    roles: ['test', 'admin']

  }


};

export default roleRouter;
