/**
 * 页面示例-路由配置
 * @author C.Robber
 */


const reBuildRouter = {
  path: '/reBuild',
  component: () => import('@/views/SecondAdmin/reBuildRouter'),
  name: 'reBuild',
  meta: {
    title: '新建档案',
    icon: 'page',
    roles: ['second', 'admin']
  }
};

export default reBuildRouter;
