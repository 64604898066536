/**
 * 页面示例-路由配置
 * @author C.Robber
 */


const queryRouter = {
  path: '/query',
  component: () => import('@/views/SecondAdmin/query'),
  name: 'query',
  meta: {
    title: '数据查询',
    icon: 'page',
    roles: ['second', 'admin']
  }
};

export default queryRouter;
