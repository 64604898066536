/**
 * 页面示例-路由配置
 * @author C.Robber
 */


 const formRouter = {
  path: '/form',
  component: () => import('@/views/SecondAdmin/form'),
  name: 'form',
  meta: {
    title: '开单管理',
    icon: 'page',
    roles: ['second', 'admin']
  }
};

export default  formRouter;
