/**
 * 页面示例-路由配置
 * @author C.Robber
 */

//import SingleLayoutIndex from '@/layout/Index';

const passwordRouter = {
  path: '/password',

  component: () => import('@/views/SecondAdmin/password'),

  name: 'password',
  meta: {
    title: '修改密码',
    icon: 'page',
    roles: ['second', 'admin']
  }
};

export default passwordRouter;
